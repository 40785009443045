import invoke from "../ajax";
import {URL} from "@/utils/request";


// 园所管理接口

export const userLogin = (params) => {
  return invoke({
    method: "POST",
    url:
      URL +
      `/teacher/login?userName=${params.name}&passWord=${params.password}`,
  }).then((res) => res.data);
};
export const visitorList = (params) => {
  return invoke({
    method: "POST",
    url: URL + `/garden/getGarden?page=1&size=10000&${params}`,
  }).then((res) => res.data);
};

export const imageList = () => {
  return invoke({
    method: "POST",
    url: URL + `/lbfile/geLbFileList?page=1&size=10000&filetype=新安轮播`,

  }).then((res) => res.data);
};

export const getTeacherList = () => {
  return invoke({
    method: "POST",
    url: URL + `/teacher/getTeacherList?page=1&size=100&name=新安街道`,

  }).then((res) => res.data);
};
export const getGarden = (item) => {
  return invoke({
    method: "POST",
    url: URL + `/garden/getGarden?page=1&size=100&name=${item}`,

  }).then((res) => res.data);
};
export const getStudentList
  =  async  (item) => {
  return  invoke({
    method: "POST",
    url: URL + `/student/getStudentList?page=1&size=100&garden=${item}`,
  }).then((res) => res.data);
};
