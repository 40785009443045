<template>
  <div class="login-box flex-col justfy-between">
    <div class="login-form-box">
      <div class="form-box flex-col align-center">
        <h3>登 录</h3>
        <el-form
        >
          <el-form-item prop="S_Account">
            <el-input
                v-model="name"
                prefix-icon="el-icon-user"
                placeholder="账号"
                clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="S_Password">
            <el-input
                v-model="password"
                prefix-icon="el-icon-lock"
                show-password
                placeholder="密码"
                clearable
            ></el-input>
          </el-form-item>
        </el-form>
<!--        <div class="form-pass justify-between">-->
<!--          <el-checkbox v-model="remberPassword">记住密码</el-checkbox>-->
<!--          <el-link type="primary" @click="forgotPassword">忘记密码</el-link>-->
<!--        </div>-->
        <el-row class="loginbtn-box">
          <el-button
              class="loginbtn"
              @click="login"
          >登录</el-button
          >
        </el-row>
        <el-row class="registr justify-end">

        </el-row>
      </div>
    </div>

    <!-- 判断是否是多组用户 -->

  </div>
</template>
<script>
import  {userLogin,getStudentList,getGarden} from '@/api/index.js'
import {md5} from '@/utils/md5'
export default {
  data() {
    return {
      name:"",
      password:"",
      studentNumber:0,
      numberCount:0,
      cont:0,

    };
  },
  mounted() {

  },
  methods: {
    // 登录
    login() {
      this.$router.push({ path:'index'})
      userLogin({name:this.name,password:md5(this.password)}).then(res=>{
        localStorage.setItem("token",res.data.token)
        localStorage.setItem("garden",res.data.garden)
        localStorage.setItem("id",res.data.id)
        this.$router.push({ path:'index'})
        res.data.garden.split(";").map(async item=>{
          // console.log( res,"res")
          await  getGarden(item.split(",")[2]).then((r)=>{
            // console.log(r.data.records[0].countNumber);
            this.cont+=Number(r.data.records[0].countNumber)
            localStorage.setItem("studentConut",this.cont)
          })

          await getStudentList(item).then( r=>{
            this.studentNumber=this.studentNumber+r.data.records.length
            localStorage.setItem("studentNumber",this.studentNumber)
          })
        })

        setTimeout(()=>{

        },100)
      })
    },


  }

};
</script>
<style lang="scss" scoped>
.login-box {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../../public/image/background.jpg) no-repeat;
  background-size: cover;
  display: flex;
 justify-content: center;
  align-items: center;

}

.form-box {
  width: 370px;
  height: 310px;
  box-sizing: border-box;
  padding: 10px 40px 0 40px;
  background: rgba(0,0,0,0);
  box-shadow: 0px 2px 30px 0px rgba(10, 107, 119, 0.3137);
  border-radius: 8px 8px 8px 8px;
  border: white 1px solid;


  h3 {
    margin-top: 20px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;

  }
  .el-form {
    width: 100%;
  }

  .form-pass {
    width: 100%;
  }

  .loginbtn-box {
    width: 100%;
    margin: 20px 0;
    .loginbtn {
      width: 100%;
      background: #14b18e;
      color: #ffffff;
    }
  }

  .registr {
    width: 100%;
  }
}

.el-link.el-link--primary {
  color: #035ada;
}
::v-deep .el-icon-user:before,
::v-deep .el-icon-lock:before {
  color: #000000;
}
</style>
