import axios from "axios";
import router from "././router/index.js";
import response from "core-js/internals/is-forced";
const ajax = axios.create({
  method: "post",
  timeout: 12000, // request timeout
  withCredentials: true,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

// response interceptor
ajax.interceptors.response.use(
  (response) => {
    switch (response.code) {
      case 40100:
        localStorage.setItem("tokenMsg", response.data.message);
        router.replace({
          path: "/",
        });
        return Promise.reject("error");
      default:
        return response;
    }
  },
  (error) => {
    console.log(response.data);

    return Promise.reject(error);
  }
);

// response interceptor
ajax.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    //判断是否存在token，如果存在将每个页面header都添加token
    config.headers.token = "";
    config.headers["Content-Type"] = "application/json";

    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") != null
    ) {
      config.headers.token = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export default ajax;
