/*
 * @Author: your name
 * @Date: 2021-10-19 13:36:44
 * @LastEditTime: 2022-10-20 14:43:44
 * @LastEditors: pluto_lichun 2826923329@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \立宝大屏\src\utils\request.js
 */
// import axios from 'axios';

const URL = "libao_api";

export {
  URL,
  // URL2
};
